import React from 'react';
import PropTypes from 'prop-types';
import BaseTable from '../../BaseTable';

const roleSort = (rowA, rowB) => {
  // Groups always take first priority
  // then sort by roles, names
  if (rowA.role === 'Group') {
    if (rowB.role === 'Group') {
      return stringSortHelper(rowA.name, rowB.name);
    } else {
      return -1;
    }
  } else {
    if (rowB.role === 'Group') {
      return 1;
    } else {
      const roleNameSort = stringSortHelper(rowA.role, rowB.role);
      if (roleNameSort !== 0) {
        return roleNameSort;
      } else {
        return nameSort(rowA, rowB);
      }
    }
  }
};

const nameSort = (rowA, rowB) => {
  // Sort by last name, first name
  const lastNameComparison = stringSortHelper(
    rowA.last_name || rowA.name,
    rowB.last_name || rowB.name
  );
  if (lastNameComparison !== 0) {
    return lastNameComparison;
  } else {
    return stringSortHelper(
      rowA.first_name || rowA.name,
      rowB.first_name || rowB.name
    );
  }
};

const stringSortHelper = (stringA, stringB) => {
  const a = stringA.toLowerCase();
  const b = stringB.toLowerCase();
  if (a > b) {
    return 1;
  }
  if (b > a) {
    return -1;
  }
  return 0;
};

const columns = (sortable) => {
  return [
    {
      name: I18n.t(
        'alert_rules.form.escalation_settings.recipients_table.columns.name'
      ),
      selector: (row) => row.name,
      sortable: sortable,
      grow: 1,
      sortFunction: nameSort,
    },
    {
      name: I18n.t(
        'alert_rules.form.escalation_settings.recipients_table.columns.role'
      ),
      selector: (row) => row.role,
      sortable: sortable,
      grow: 1,
      sortFunction: roleSort,
    },
    {
      name: I18n.t(
        'alert_rules.form.escalation_settings.recipients_table.columns.contact'
      ),
      selector: (row) => row.contact,
      sortable: sortable,
      grow: 2,
    },
  ];
};

const RecipientsTable = ({
  data,
  customStyles = {},
  fixedHeader = false,
  fixedHeaderScrollHeight = '',
  onRowClicked = () => {},
  selectableRowSelected = () => {},
  selectableRowsHighlight = false,
  sortable = false,
}) => {
  return (
    <BaseTable
      columns={columns(sortable)}
      data={data}
      customStyles={customStyles}
      defaultSortFieldId={2}
      selectableRowSelected={selectableRowSelected}
      selectableRowsHighlight={selectableRowsHighlight}
      onRowClicked={onRowClicked}
      fixedHeader={fixedHeader}
      fixedHeaderScrollHeight={fixedHeaderScrollHeight}
    />
  );
};

RecipientsTable.propTypes = {
  customStyles: PropTypes.object,
  data: PropTypes.array.isRequired,
  fixedHeader: PropTypes.bool,
  fixedHeaderScrollHeight: PropTypes.string,
  onRowClicked: PropTypes.func,
  selectableRowSelected: PropTypes.func,
  selectableRowsHighlight: PropTypes.bool,
  sortable: PropTypes.bool,
};

export default RecipientsTable;
