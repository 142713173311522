// Alert Types
export const ALERT_TYPE_THRESHOLD = 'threshold';
export const ALERT_TYPE_CONNECTION = 'connection';
export const ALERT_TYPE_POWER = 'power';
export const ALERT_TYPE_ERROR = 'error';

// Sensor Types
export const SENSOR_TYPE_BATTERY = 'battery';

// Delivery Types
export const DELIVERY_TYPE_EMAIL = 1;
export const DELIVERY_TYPE_SMS = 2;
export const DELIVERY_TYPE_APP = 3;
export const DELIVERY_TYPE_VOICE_CALL = 4;

export const DEFAULT_ESCALATION_DELAY = 60;
