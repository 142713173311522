import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { HiddenField } from 'react-rails-form-helpers';
import CheckBoxGroupOption from './CheckBoxGroupOption';
import EmailIcon from '../../icons/delivery_types/EmailIcon';
import SmsIcon from '../../icons/delivery_types/SmsIcon';
import AppIcon from '../../icons/delivery_types/AppIcon';
import VoiceCallIcon from '../../icons/delivery_types/VoiceCallIcon';
import {
  DELIVERY_TYPE_EMAIL,
  DELIVERY_TYPE_SMS,
  DELIVERY_TYPE_APP,
  DELIVERY_TYPE_VOICE_CALL,
} from '../constants';

const getOptionContent = (deliveryTypeId) => {
  switch (deliveryTypeId) {
    case DELIVERY_TYPE_EMAIL:
      return (
        <>
          <EmailIcon />
          <span>{I18n.t('alert_rules.form.delivery_types.email')}</span>
        </>
      );
    case DELIVERY_TYPE_SMS:
      return (
        <>
          <SmsIcon />
          <span>{I18n.t('alert_rules.form.delivery_types.sms')}</span>
        </>
      );
    case DELIVERY_TYPE_APP:
      return (
        <>
          <AppIcon />
          <span>{I18n.t('alert_rules.form.delivery_types.app')}</span>
        </>
      );
    case DELIVERY_TYPE_VOICE_CALL:
      return (
        <>
          <VoiceCallIcon />
          <span>{I18n.t('alert_rules.form.delivery_types.voice_call')}</span>
        </>
      );
  }
};

const DeliveryTypeCheckBoxGroup = ({
  name,
  options,
  selectedDeliveryTypes,
  setSelectedDeliveryTypes,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const addToSelectedOptions = (deliveryTypeId) => {
    setSelectedDeliveryTypes([
      ...selectedDeliveryTypes,
      parseInt(deliveryTypeId, 10),
    ]);
  };

  const removeFromSelectedOptions = (deliveryTypeId) => {
    if (selectedDeliveryTypes.length > 1) {
      const parsedDeliveryTypeId = parseInt(deliveryTypeId, 10);
      const index = selectedDeliveryTypes.indexOf(parsedDeliveryTypeId);
      if (index > -1) {
        selectedDeliveryTypes.splice(index, 1);
      }
      setSelectedDeliveryTypes([...selectedDeliveryTypes]);
      return true;
    } else {
      setOpen(true);
      return false;
    }
  };

  return (
    <div className={'card-wrapper check_boxes'}>
      <HiddenField
        name={name}
        value={`[${selectedDeliveryTypes.join(', ')}]`}
      />
      {options.map((deliveryTypeId, index) => (
        <CheckBoxGroupOption
          key={index}
          name={deliveryTypeId.toString()}
          checked={selectedDeliveryTypes.includes(deliveryTypeId)}
          addToSelectedOptions={addToSelectedOptions}
          removeFromSelectedOptions={removeFromSelectedOptions}
        >
          {getOptionContent(deliveryTypeId)}
        </CheckBoxGroupOption>
      ))}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {I18n.t(
              'alert_rules.form.escalation_settings.delivery_types_dialog.description'
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={handleClose}>{I18n.t('general.ok')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeliveryTypeCheckBoxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedDeliveryTypes: PropTypes.array.isRequired,
  setSelectedDeliveryTypes: PropTypes.func.isRequired,
};

export default DeliveryTypeCheckBoxGroup;
