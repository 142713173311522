import { connect, Provider } from 'react-redux';
import AlertDeliverySettingFormElements from './AlertDeliverySettingFormElements';
import store from '../store';
import React from 'react';
import { ALERT_TYPE_POWER, SENSOR_TYPE_BATTERY } from '../constants';

// TODO We won't need this once Alert Rule Edit is fully converted into React
// use redux hooks in the components instead
const AlertDeliverySettingFormElementsContainer = (props) => {
  return (
    <Provider store={store}>
      <ConnectedAlertDeliverySettingFormElements {...props} />
    </Provider>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { alertType, sensorTypes } = state.alertRule;
  const selectedBatteryOnly =
    sensorTypes.length === 1 && sensorTypes[0] === SENSOR_TYPE_BATTERY;
  const showRepeatIntervalOptions =
    alertType !== ALERT_TYPE_POWER || !selectedBatteryOnly;

  return {
    ...ownProps,
    showRepeatIntervalOptions,
  };
};

const ConnectedAlertDeliverySettingFormElements = connect(
  mapStateToProps,
  {}
)(AlertDeliverySettingFormElements);

export default AlertDeliverySettingFormElementsContainer;
