import React, { useState, KeyboardEventHandler } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import { ValidateEmail } from '../../../utils/Validators';
import { ActionMeta, OnChangeValue } from 'react-select';
import { Label, HiddenField } from 'react-rails-form-helpers';

const customStyles = {
	input: (base) => ({
		...base,
		cursor: 'text'
	}),
	multiValue: (base) => ({
		...base,
		backgroundColor: '#1C5BFE',
		borderRadius: 12,
		color: 'white',
	}),
	multiValueLabel: (base) => ({
		...base,
		color: 'white',
		fontSize: 13,
		fontWeight: 600,
		lineHeight: '18px',
		paddingLeft: '10px'
	}),
	placeholder: (base) => ({
		...base,
		fontSize: 14,
		fontStyle: 'italic',
		fontWeight: 500,
		lineHeight: '19px'
	}),
	valueContainer: (base) => ({
		...base,
		padding: '0 8px'
	}),
};

const customTheme = (theme) => ({
	...theme,
	borderRadius: 8,
	colors: {
		...theme.colors,
		neutral20: '#E8E8E8',
		neutral30: '#E8E8E8',
		primary: '#1446AE',
	},
});

const createOption = (label) => ({
	label,
	value: label,
});

const ValidationMessage = ({ message }) => {
  const showValidationMessage = !!message;
  const isValid = showValidationMessage && ValidateEmail(message);

  return (
    showValidationMessage && (
      <>
        {isValid && (
          <span className={'validation_message valid'}>
            {I18n.t(
              'alert_rules.form.escalation_settings.additional_emails.validation_message.valid'
            )}
          </span>
        )}
        {!isValid && (
          <span className={'validation_message invalid'}>
            {I18n.t(
              'alert_rules.form.escalation_settings.additional_emails.validation_message.invalid'
            )}
          </span>
        )}
      </>
    )
  );
};

ValidationMessage.propTypes = {
	message: PropTypes.string.isRequired,
};

const AdditionalEmailsInput = ({ additionalEmails }) => {
  const [inputValue, setInputValue] = useState('');
	const [values, setValues] = useState(additionalEmails.map((email) => createOption(email)));

  const handleChange = (value, actionMeta) => {
    setValues(value);
  };
  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };
  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
	      if (ValidateEmail(inputValue)) {
		      setInputValue('');
		      setValues([...values, createOption(inputValue)]);
	      }
	      event.preventDefault();
    }
  };

  return (
    <>
      <Label className={'row small-collapse'}>
        Additional Email Recipients
      </Label>
      <HiddenField
        name={'additional_emails'}
        value={`[${values.map(({ value }) => `"${value}"`).join(', ')}]`}
      />
      <CreatableSelect
        isMulti
        components={{
          DropdownIndicator: null,
        }}
        inputValue={inputValue}
        isClearable
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={I18n.t(
          'alert_rules.form.escalation_settings.additional_emails.placeholder'
        )}
        value={values}
        theme={customTheme}
        styles={customStyles}
      />
      <ValidationMessage message={inputValue} />
    </>
  );
};

AdditionalEmailsInput.propTypes = {
  additionalEmails: PropTypes.array.isRequired,
};

export default AdditionalEmailsInput;
