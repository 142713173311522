import { configureStore } from '@reduxjs/toolkit';
import alertRuleReducer from '../../slices/alertRuleSlice';
// TODO this file should be moved to upper levels once we have the whole page
// fully converted to React/Redux
const store = configureStore({
  reducer: {
    alertRule: alertRuleReducer,
  },
});

export default store;
