import React from 'react';
import PropTypes from 'prop-types';

const RadioGroupOption = ({
  checked,
  children,
  onClick,
  additionalClassNames,
}) => (
  <span
    className={[...additionalClassNames, 'radio'].join(' ')}
    onClick={onClick}
  >
    <label className={`${checked ? 'checked' : ''}`}>{children}</label>
  </span>
);

RadioGroupOption.propTypes = {
  checked: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
  onClick: PropTypes.func.isRequired,
  additionalClassNames: PropTypes.arrayOf(PropTypes.string),
};

export default RadioGroupOption;
