import { Button, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useLazyCheckSSOPathQuery } from '../../features/auth';
import { Form, Input } from './styles';

const EmailForm: React.FC<{
  onSubmit: (values: { email: string }) => void;
}> = ({ onSubmit }) => {
  const { t } = useTranslation(['login', 'common']);
  const navigate = useNavigate();
  const [checkSSOPath, { isFetching: isSubmitting }] =
    useLazyCheckSSOPathQuery();
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t('login:invalidEmail'))
      .required(t('login:emailRequired')),
  });

  const { errors, getFieldProps, isValid, submitForm, touched } = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const result = await checkSSOPath({ email: values.email }).unwrap();
      if (result.sso_path) {
        console.log(`sso_path: ${result.sso_path}`);
        navigate(result.sso_path);
      } else {
        onSubmit(values);
      }
      setSubmitting(false);
    },
    validationSchema,
  });

  return (
    <Form
      autoComplete="on"
      data-testid="email-form"
      onSubmit={(e) => e.preventDefault()}
    >
      <Input
        label={t('login:email')}
        {...getFieldProps('email')}
        autoComplete="username"
        autoFocus
        data-testid="email-input"
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
        id="email"
        name="email"
        type="text"
        variant="standard"
      />
      <Button
        color="primary"
        data-testid="next-button"
        disabled={!isValid || isSubmitting}
        endIcon={isSubmitting && <CircularProgress size={20} />}
        onClick={submitForm}
        type="submit"
        variant="contained"
      >
        {isSubmitting ? t('common:loading') : t('login:next')}
      </Button>
    </Form>
  );
};

export default EmailForm;
