import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ArrayFields, FieldsFor, Label } from 'react-rails-form-helpers';
import NewIcon from '../icons/NewIcon';
import AlertDeliverySettingFormElementsContainer from './partials/AlertDeliverySettingFormElementsContainer';

const MAX_DELIVERY_SETTING_COUNT = 4;

const AlertDeliverySettingForm = ({
  customerGroupName,
  alertDeliverySettings,
  defaultAlertDeliverySetting,
  escalationEnabled,
  intervalOptions,
  maxRepeatsOptions,
  deliveryTypeOptions,
  recipientOptions,
  escalationDelayOptions,
}) => {
  const [deliverySettings, setDeliverySettings] = useState(
    alertDeliverySettings
  );

  const addEscalation = () => {
    setDeliverySettings([...deliverySettings, defaultAlertDeliverySetting]);
  };

  const removeEscalation = (escalationLevel) => {
    deliverySettings.splice(escalationLevel, 1);
    setDeliverySettings([...deliverySettings]);
  };

  // Allow up to 3 escalations (4 levels in total with primary)
  const showAddEscalation =
    escalationEnabled && deliverySettings.length < MAX_DELIVERY_SETTING_COUNT;

  return (
    <FieldsFor name="alert_rule">
      <ArrayFields name="alert_delivery_settings_attributes">
        <div className={'alert_delivery_setting_form'}>
          {deliverySettings.map((deliverySetting, index) => (
            <AlertDeliverySettingFormElementsContainer
              key={index}
              customerGroupName={customerGroupName}
              alertDeliverySetting={deliverySetting}
              escalationLevel={index}
              hasEscalations={deliverySettings.length > 1}
              intervalOptions={intervalOptions}
              maxRepeatsOptions={maxRepeatsOptions}
              deliveryTypeOptions={deliveryTypeOptions}
              recipientOptions={recipientOptions}
              escalationDelayOptions={escalationDelayOptions}
              removeEscalation={removeEscalation}
            />
          ))}
          {showAddEscalation && (
            <div className={'row-wrapper action-row-wrapper'}>
              <div className={'action-row'} onClick={addEscalation}>
                <NewIcon />
                <Label>
                  {I18n.t(
                    'alert_rules.form.escalation_settings.actions.add_escalation'
                  )}
                </Label>
              </div>
            </div>
          )}
        </div>
      </ArrayFields>
    </FieldsFor>
  );
};

AlertDeliverySettingForm.propTypes = {
  customerGroupName: PropTypes.string.isRequired,
  alertDeliverySettings: PropTypes.array.isRequired,
  defaultAlertDeliverySetting: PropTypes.object.isRequired,
  deliveryTypeOptions: PropTypes.array.isRequired,
  escalationDelayOptions: PropTypes.array.isRequired,
  intervalOptions: PropTypes.array.isRequired,
  maxRepeatsOptions: PropTypes.array.isRequired,
  recipientOptions: PropTypes.array.isRequired,
};

export default AlertDeliverySettingForm;
