import { faker } from '@faker-js/faker';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { fireEvent, render, screen, waitFor } from '@testing-library/react';
import React from 'react';
import { Provider } from 'react-redux';
import { MemoryRouter } from 'react-router-dom';
import { describe, expect, test, vi } from 'vitest';

import { server } from '../../features/auth/mocks/server';
import translations from '../../translations';
import EmailForm from './EmailForm';
import store from './store';

const theme = createTheme();

const renderWithProviders = (ui: React.ReactElement) => {
  return render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MemoryRouter>{ui}</MemoryRouter>
      </ThemeProvider>
    </Provider>,
  );
};

describe('EmailForm', () => {
  const mockOnSubmit = vi.fn();

  beforeAll(() => {
    translations.init();
    server.listen();
  });
  afterEach(() => server.resetHandlers());
  afterAll(() => server.close());

  test('renders email input and next button', () => {
    renderWithProviders(<EmailForm onSubmit={mockOnSubmit} />);

    expect(screen.getByLabelText(/email/i)).toBeInTheDocument();
    expect(screen.getByTestId('next-button')).toBeInTheDocument();
  });

  test('handles form submission with valid email', async () => {
    renderWithProviders(<EmailForm onSubmit={mockOnSubmit} />);

    const email = faker.internet.email();

    fireEvent.change(screen.getByLabelText(/email/i), {
      target: { value: email },
    });

    fireEvent.click(screen.getByTestId('next-button'));

    await waitFor(() => {
      expect(mockOnSubmit).toHaveBeenCalledWith({ email });
    });
  });

  test('displays validation error for empty email', async () => {
    renderWithProviders(<EmailForm onSubmit={mockOnSubmit} />);

    fireEvent.click(screen.getByTestId('next-button'));

    await waitFor(() => {
      expect(screen.getByText(/email is required/i)).toBeInTheDocument();
    });
  });

  test('displays validation error for invalid email', async () => {
    renderWithProviders(<EmailForm onSubmit={mockOnSubmit} />);

    const invalidEmail = faker.string.alphanumeric(10);

    fireEvent.change(screen.getByLabelText(/email/i), {
      target: { value: invalidEmail },
    });

    fireEvent.click(screen.getByTestId('next-button'));

    await waitFor(() => {
      expect(screen.getByText(/invalid email address/i)).toBeInTheDocument();
    });
  });
});
