import React from 'react';
import PropTypes from 'prop-types';
import merge from 'deepmerge';
import DataTable, { createTheme } from 'react-data-table-component';

createTheme(
  'elemental',
  {
    text: {
      primary: '#444C5B',
    },
    background: {
      default: '#FFFFFF',
    },
    selected: {
      default: 'rgba(28, 91, 254, 0.5)',
      text: '#FFFFFF',
    },
    highlightOnHover: {
      default: '#1C5BFE',
      text: '#FFFFFF',
    },
    striped: {
      default: '#F9F9F9',
    },
  },
  'default'
);

const baseStyles = {
  head: {
    style: {
      color: '#1A1919',
      fontSize: '13px',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
  },
  headRow: {
    style: {
      minHeight: '45px',
      backgroundColor: 'rgba(28, 91, 254, 0.1)',
      borderBottomStyle: 'none',
    },
  },
  rows: {
    style: {
      fontSize: '14px',
      fontWeight: 500,
      '&:not(:last-of-type)': {
        borderBottomStyle: 'none',
      },
    },
  },
};

const BaseTable = ({
  data,
  columns,
  customStyles = {},
  defaultSortFieldId = null,
  fixedHeader = false,
  fixedHeaderScrollHeight = '',
  onRowClicked = () => {},
  selectableRowSelected = () => {},
  selectableRowsHighlight = false,
}) => {
  return (
    <DataTable
      columns={columns}
      customStyles={merge(baseStyles, customStyles)}
      data={data}
      defaultSortFieldId={defaultSortFieldId}
      highlightOnHover
      responsive
      selectableRowSelected={selectableRowSelected}
      selectableRowsHighlight={selectableRowsHighlight}
      striped
      theme={'elemental'}
      onRowClicked={onRowClicked}
      fixedHeader={fixedHeader}
      fixedHeaderScrollHeight={fixedHeaderScrollHeight}
    />
  );
};

BaseTable.propTypes = {
  columns: PropTypes.array.isRequired,
  customStyles: PropTypes.object,
  data: PropTypes.array.isRequired,
  defaultSortFieldId: PropTypes.number,
  fixedHeader: PropTypes.bool,
  fixedHeaderScrollHeight: PropTypes.string,
  onRowClicked: PropTypes.func,
  selectableRowSelected: PropTypes.func,
  selectableRowsHighlight: PropTypes.bool,
};

export default BaseTable;
