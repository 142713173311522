import { createSlice } from '@reduxjs/toolkit';

export const alertRuleSlice = createSlice({
  name: 'alertRule',
  initialState: {
    alertType: '',
    sensorTypes: [],
  },
  reducers: {
    setAlertType: (state, action) => {
      state.alertType = action.payload;
    },
    setSensorTypes: (state, action) => {
      state.sensorTypes = [...action.payload];
    },
  },
});

export const { setAlertType, setSensorTypes } = alertRuleSlice.actions;

export default alertRuleSlice.reducer;
