import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { HashFields, HiddenField, Label } from 'react-rails-form-helpers';
import DeliveryTypeCheckBoxGroup from './DeliveryTypeCheckBoxGroup';
import RecipientsDialog from './RecipientsDialog';
import AdditionalEmailsInput from './AdditionalEmailsInput';
import SelectWithOptions from './SelectWithOptions';
import RecipientsTable from './RecipientsTable';
import { DEFAULT_ESCALATION_DELAY, DELIVERY_TYPE_EMAIL } from '../constants';

const getEscalationLevelText = (escalationLevel) => {
  if (escalationLevel > 0) {
    return I18n.t('alert_delivery_settings.levels.escalation', {
      level: escalationLevel,
    });
  } else {
    return I18n.t('alert_delivery_settings.levels.primary');
  }
};

const getEscalationTitle = ({ hasEscalations, escalationLevel }) => {
  const title = I18n.t('alert_rules.form.escalation_settings.title');
  if (hasEscalations) {
    const escalationLevelText = getEscalationLevelText(escalationLevel);
    return `${escalationLevelText} ${title}`;
  } else {
    return title;
  }
};

const recipientsTableStyles = {
  table: {
    style: {
      border: '1px solid #E8E8E8',
      borderRadius: '8px',
    },
  },
};

const AlertDeliverySettingFormElements = ({
  customerGroupName,
  alertDeliverySetting,
  hasEscalations,
  escalationLevel,
  intervalOptions,
  maxRepeatsOptions,
  deliveryTypeOptions,
  recipientOptions,
  escalationDelayOptions,
  removeEscalation,
  showRepeatIntervalOptions,
}) => {
  const repeatInterval = alertDeliverySetting.repeat_interval;
  const maxRepeats = alertDeliverySetting.max_repeats;
  const [selectedRecipients, setSelectedRecipients] = useState(
    alertDeliverySetting.selected_recipients
  );
  const [selectedDeliveryTypes, setSelectedDeliveryTypes] = useState(
    alertDeliverySetting.selected_delivery_types
  );
  const isEscalation = escalationLevel > 0;
  const alertOrEscalationText = isEscalation
    ? I18n.t('alert_rules.form.escalation_settings.labels.escalation')
    : I18n.t('alert_rules.form.escalation_settings.labels.alert');

  const previousEscalationLevelText = getEscalationLevelText(
    escalationLevel - 1
  );
  const delayLabel =
    escalationLevel > 1
      ? I18n.t('alert_rules.form.escalation_settings.labels.delay')
      : I18n.t('alert_rules.form.escalation_settings.labels.delay_within');
  const thenLabel =
    escalationLevel > 1
      ? I18n.t('alert_rules.form.escalation_settings.labels.after_then', {
          escalation_level: previousEscalationLevelText,
        })
      : I18n.t('alert_rules.form.escalation_settings.labels.then');

  return (
    <div className={'row-wrapper'}>
      <span className={'delivery_setting_header'}>
        <div className={'row small-collapse'}>
          <h4 className={'collapse'}>
            {getEscalationTitle({ hasEscalations, escalationLevel })}
          </h4>
        </div>
        {isEscalation && (
          <Button
            className={'button_remove_escalation'}
            variant="outlined"
            onClick={() => removeEscalation(escalationLevel)}
          >
            {I18n.t(
              'alert_rules.form.escalation_settings.actions.remove_escalation'
            )}
          </Button>
        )}
      </span>
      <div className={'row small-collapse'}>
        <HashFields name={escalationLevel.toString()}>
          {alertDeliverySetting.id && (
            <HiddenField name="id" value={alertDeliverySetting.id} />
          )}
          <HiddenField name="escalation_level" value={escalationLevel} />
          {isEscalation && (
            <div className={'inline_row'}>
              <Label>{delayLabel}</Label>
              <SelectWithOptions
                name={'escalation_delay'}
                defaultValue={
                  alertDeliverySetting.escalation_delay ||
                  DEFAULT_ESCALATION_DELAY
                }
                options={escalationDelayOptions}
              />
              <Label>{thenLabel}:</Label>
            </div>
          )}
          <div className={'inline_row'}>
            {showRepeatIntervalOptions && (
              <>
                <Label>
                  {I18n.t(
                    'alert_rules.form.escalation_settings.labels.deliver_every',
                    {
                      alert_or_escalation: alertOrEscalationText,
                    }
                  )}
                </Label>
                <SelectWithOptions
                  name={'repeat_interval'}
                  defaultValue={repeatInterval}
                  options={intervalOptions}
                />
                <Label>
                  {I18n.t(
                    'alert_rules.form.escalation_settings.labels.max_repeats'
                  )}
                </Label>
                <SelectWithOptions
                  name={'max_repeats'}
                  defaultValue={maxRepeats}
                  options={maxRepeatsOptions}
                />
                <Label>
                  {I18n.t('alert_rules.form.escalation_settings.labels.via')}:
                </Label>
              </>
            )}
            {!showRepeatIntervalOptions && (
              <Label>
                {I18n.t(
                  'alert_rules.form.escalation_settings.labels.deliver_via',
                  {
                    alert_or_escalation: alertOrEscalationText,
                  }
                )}
                :
              </Label>
            )}
            <DeliveryTypeCheckBoxGroup
              name={'delivery_types'}
              options={deliveryTypeOptions}
              selectedDeliveryTypes={selectedDeliveryTypes}
              setSelectedDeliveryTypes={setSelectedDeliveryTypes}
            />
          </div>
          <div className={'recipients_header'}>
            <Label
              className={'row small-collapse'}
              htmlFor={'alert_delivery_recipients'}
            >
              {I18n.t('alert_rules.form.escalation_settings.labels.recipients')}
            </Label>
            <RecipientsDialog
              customerGroupName={customerGroupName}
              selectedRecipients={selectedRecipients}
              setSelectedRecipients={setSelectedRecipients}
              recipientOptions={recipientOptions}
            />
          </div>
          <HiddenField
            name={'alert_delivery_recipients'}
            value={`[${selectedRecipients
              .map((recipient) => `"${recipient.id}"`)
              .join(', ')}]`}
          />
          <RecipientsTable
            data={selectedRecipients}
            customStyles={recipientsTableStyles}
          />
          {selectedDeliveryTypes.includes(DELIVERY_TYPE_EMAIL) && (
            <AdditionalEmailsInput
              additionalEmails={alertDeliverySetting.additional_emails}
            />
          )}
        </HashFields>
      </div>
    </div>
  );
};

AlertDeliverySettingFormElements.propTypes = {
  customerGroupName: PropTypes.string.isRequired,
  alertDeliverySetting: PropTypes.object,
  deliveryTypeOptions: PropTypes.array.isRequired,
  escalationDelayOptions: PropTypes.array.isRequired,
  escalationLevel: PropTypes.number.isRequired,
  hasEscalations: PropTypes.bool.isRequired,
  intervalOptions: PropTypes.array.isRequired,
  maxRepeatsOptions: PropTypes.array.isRequired,
  recipientOptions: PropTypes.array.isRequired,
  removeEscalation: PropTypes.func.isRequired,
  showRepeatIntervalOptions: PropTypes.bool,
};

export default AlertDeliverySettingFormElements;
