import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './en';

const init = () => {
  i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      defaultNS: 'common',
      interpolation: {
        escapeValue: false,
      },
      ns: Object.keys(en),
      resources: {
        en,
      },
      supportedLngs: ['en'],
    });
};

const translations = {
  init,
};

export default translations;
