import React, { useState } from 'react';
import PropTypes from 'prop-types';

const CheckBoxGroupOption = ({
  name,
  checked,
  children,
  addToSelectedOptions,
  removeFromSelectedOptions,
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  const handleClick = (e) => {
    e.preventDefault();
    if (isChecked) {
      const success = removeFromSelectedOptions(name);
      if (success) setIsChecked(!isChecked);
    } else {
      addToSelectedOptions(name);
      setIsChecked(!isChecked);
    }
  };

  return (
    <span className={'checkbox'} onClick={handleClick}>
      <label className={`collection_check_boxes ${isChecked ? 'checked' : ''}`}>
        {children}
      </label>
    </span>
  );
};

CheckBoxGroupOption.propTypes = {
  addToSelectedOptions: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  children: PropTypes.element,
  name: PropTypes.string.isRequired,
  removeFromSelectedOptions: PropTypes.func.isRequired,
};

export default CheckBoxGroupOption;
